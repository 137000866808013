import { FC, useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import Modal from "../Modal";
import { Formik } from "formik";
import classNames from "classnames";
import { PDocumentDetailModal } from "./PDocumentDetailModal";
import { FormFileUpload, FormTextInput } from "../FormFields";
import { LinkText, PrimaryButton, SecondaryButton } from "../Buttons";
import {
  PDocumentInterface,
  PDocumentStatus,
  PDocumentType,
  Undefinable,
} from "../../interfaces";
import {
  formatPDocumentType,
  currencyExchangeText,
  useCurrencyExchanges,
  pdocumentStatusFormat,
} from "../../utils";
import { updatePDocument } from "../../services/pdocumentServices";
import { useAppSelector } from "../../store/hooks";
import {
  alertService,
  loaderService,
  previewFileDO,
  uploadFileDO,
} from "../../services";
import { v4 } from "uuid";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";

const validationSchema = Yup.object().shape({
  pdocumentNumber: Yup.string().required("Debe ingresar un número de factura"),
  controlNumber: Yup.string().required("Debe ingresar un número de control"),
  file: Yup.mixed<File[]>()
    .required("Debe cargar un archivo")
    .test("fileFormat", "El archivo debe ser formato PDF o imagen", (file) => {
      if (!file) return false;
      return (
        file[0].type === "application/pdf" || file[0].type.startsWith("image/")
      );
    }),
});
const updateValidationSchema = Yup.object().shape({
  pdocumentNumber: Yup.string().required("Debe ingresar un número de factura"),
  controlNumber: Yup.string().required("Debe ingresar un número de control"),
  file: Yup.mixed<File[]>().test(
    "fileFormat",
    "El archivo debe ser formato PDF o imagen",
    (file) => {
      if (!file) return true;
      return (
        file[0].type === "application/pdf" || file[0].type.startsWith("image/")
      );
    }
  ),
});
interface PDocumentTableItemProps {
  index: number;
  isActive?: boolean;
  pdocument: PDocumentInterface;
  setReload: (value: string) => void;
}
export const PDocumentTableItem: FC<PDocumentTableItemProps> = ({
  index,
  pdocument,
  isActive = false,
  setReload,
}) => {
  const exchanges = useCurrencyExchanges();
  const user = useAppSelector((state) => state.user.user);

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openAttachmentModal, setOpenAttachmentModal] = useState(false);

  const handleSubmit = useCallback(
    async (values: {
      file: Undefinable<File[]>;
      pdocumentNumber: string;
      controlNumber: string;
    }) => {
      if (!values.file || !user?.userLogin) return;

      loaderService.start();
      const attachmentResponse = await uploadFileDO(
        values.file[0],
        32,
        7,
        user.userLogin
      );
      if (attachmentResponse.didError || !attachmentResponse.model) {
        loaderService.stop();
        alertService.error(
          "Hubo un error al guardar el adjunto.",
          attachmentResponse.errorMessage
        );
        return;
      }

      const response = await updatePDocument(
        pdocument.documentID,
        values.pdocumentNumber,
        values.controlNumber,
        attachmentResponse.model.attachmentID,
        PDocumentStatus.TO_APPROVE,
        PDocumentType.FCT,
        user?.userLogin
      );
      loaderService.stop();
      if (response.didError) {
        alertService.error("Hubo un error al actualizar el documento.");
        return;
      }

      setReload(v4());
      setOpenAttachmentModal(false);
    },
    [setReload]
  );

  const handleEdit = useCallback(
    async (values: {
      file: Undefinable<File[]>;
      pdocumentNumber: string;
      controlNumber: string;
    }) => {
      if (!user?.userLogin) return;

      let attachmentID: Undefinable<string> = undefined;
      loaderService.start();

      if (values.file) {
        const attachmentResponse = await uploadFileDO(
          values.file[0],
          32,
          7,
          user.userLogin
        );
        if (attachmentResponse.didError || !attachmentResponse.model) {
          loaderService.stop();
          alertService.error("Hubo un error al guardar el adjunto.");
          return;
        }
        attachmentID = attachmentResponse.model.attachmentID;
      } else {
        attachmentID = pdocument.attachmentID;
      }

      const response = await updatePDocument(
        pdocument.documentID,
        values.pdocumentNumber,
        values.controlNumber,
        attachmentID,
        PDocumentStatus.TO_APPROVE,
        PDocumentType.FCT,
        user?.userLogin
      );
      loaderService.stop();
      if (response.didError) {
        alertService.error("Hubo un error al actualizar el documento.");
        return;
      }

      setReload(v4());
      setOpenEditModal(false);
    },
    [setReload]
  );

  const handleAttachmentDelete = useCallback(async () => {
    if (!user?.userLogin) return;

    loaderService.start();
    const response = await updatePDocument(
      pdocument.documentID,
      undefined,
      undefined,
      undefined,
      PDocumentStatus.PENDING,
      PDocumentType.ODF,
      user?.userLogin
    );
    loaderService.stop();
    if (response.didError) {
      alertService.error("Hubo un error al actualizar el documento.");
      return;
    }

    setReload(v4());
    setOpenDeleteModal(false);
  }, [setReload]);

  const handleAttachmentPreview = async () => {
    if (!pdocument.attachmentID) return;

    loaderService.start();
    await previewFileDO(pdocument.attachmentID);
    loaderService.stop();
  };

  return (
    <tr
      onClick={() => setOpenDetailsModal(true)}
      className={classNames(
        "hover:bg-gray-100 h-16 cursor-pointer",
        index % 2 === 0 && "bg-gray-50"
      )}
    >
      <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
        {pdocument.documentNumber ?? pdocument.reportID}
      </td>

      <td
        className={classNames(
          "px-4 py-4 text-xs text-left text-gray-500 font-semibold truncate",
          isActive && "hidden"
        )}
      >
        {pdocument.fiscalControlNumber}
      </td>

      <td className="flex items-center px-4 py-4 text-xs text-gray-500 font-semibold truncate">
        <div className="flex flex-col">
          {formatPDocumentType(pdocument.documentTypeID)}

          <div className={classNames(!pdocument.attachmentID && "hidden")}>
            <LinkText
              text="Ver adjunto"
              onClick={(e) => {
                e.stopPropagation();
                handleAttachmentPreview();
              }}
            />
          </div>

          <div
            className={classNames(
              (pdocument.documentTypeID !== PDocumentType.ODF ||
                !!pdocument.attachmentID) &&
                "hidden"
            )}
          >
            <LinkText
              text="Cargar factura"
              onClick={(e) => {
                e.stopPropagation();
                setOpenAttachmentModal(true);
              }}
            />
          </div>

          <div onClick={(e) => e.stopPropagation()}>
            <Modal
              openModal={openDetailsModal}
              setOpenModal={setOpenDetailsModal}
              className="!px-10 !pt-8 !pb-14"
            >
              <PDocumentDetailModal pdocument={pdocument} />
            </Modal>

            <Modal
              openModal={openAttachmentModal}
              setOpenModal={setOpenAttachmentModal}
            >
              <Formik
                initialValues={{
                  file: undefined as Undefinable<File[]>,
                  pdocumentNumber: "",
                  controlNumber: "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {(formik) => {
                  return (
                    <form
                      onSubmit={formik.handleSubmit}
                      className="flex flex-col gap-4 min-w-[40rem]"
                    >
                      <div className="flex flex-col md:flex-row md:gap-2">
                        <p className="text-xl">
                          Cargar adjunto al reporte{" "}
                          <span className="font-semibold text-indigo-600">
                            {pdocument.documentNumber ?? pdocument.reportID}
                          </span>
                        </p>
                      </div>

                      <div className="flex gap-4 w-full">
                        <div className="flex-1">
                          <FormTextInput
                            name="pdocumentNumber"
                            label="Número de Factura"
                            value={formik.values.pdocumentNumber}
                            error={
                              formik.touched.pdocumentNumber &&
                              formik.errors.pdocumentNumber
                                ? formik.errors.pdocumentNumber
                                : ""
                            }
                            onChange={formik.handleChange}
                          />
                        </div>

                        <div className="flex-1">
                          <FormTextInput
                            name="controlNumber"
                            label="Número de Control"
                            value={formik.values.controlNumber}
                            error={
                              formik.touched.controlNumber &&
                              formik.errors.controlNumber
                                ? formik.errors.controlNumber
                                : ""
                            }
                            onChange={formik.handleChange}
                          />
                        </div>
                      </div>

                      <div>
                        <FormFileUpload
                          error={
                            formik.touched.file && formik.errors.file
                              ? formik.errors.file
                              : ""
                          }
                          description="El archivo debe ser formato PDF o imagen"
                          onSelectFile={(file) =>
                            formik.setFieldValue("file", file)
                          }
                          className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50"
                        />
                      </div>

                      <div className="flex w-full justify-between mt-6">
                        <SecondaryButton
                          onClick={() => setOpenAttachmentModal(false)}
                          className="w-40"
                          type="button"
                        >
                          Cancelar
                        </SecondaryButton>

                        <PrimaryButton className="w-40" type="submit">
                          Cargar
                        </PrimaryButton>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </Modal>

            <Modal openModal={openEditModal} setOpenModal={setOpenEditModal}>
              <Formik
                initialValues={{
                  file: undefined as Undefinable<File[]>,
                  pdocumentNumber: pdocument.documentNumber ?? "",
                  controlNumber: pdocument.fiscalControlNumber ?? "",
                }}
                validationSchema={updateValidationSchema}
                onSubmit={handleEdit}
              >
                {(formik) => {
                  return (
                    <form
                      onSubmit={formik.handleSubmit}
                      className="flex flex-col gap-4 min-w-[40rem]"
                    >
                      <div className="flex flex-col md:flex-row md:gap-2">
                        <p className="text-xl">
                          Modificar reporte{" "}
                          <span className="font-semibold text-indigo-600">
                            {pdocument.documentNumber ?? pdocument.reportID}
                          </span>
                        </p>
                      </div>

                      <div className="flex gap-4 w-full">
                        <div className="flex-1">
                          <FormTextInput
                            name="pdocumentNumber"
                            label="Número de Factura"
                            value={formik.values.pdocumentNumber}
                            error={
                              formik.touched.pdocumentNumber &&
                              formik.errors.pdocumentNumber
                                ? formik.errors.pdocumentNumber
                                : ""
                            }
                            onChange={formik.handleChange}
                          />
                        </div>

                        <div className="flex-1">
                          <FormTextInput
                            name="controlNumber"
                            label="Número de Control"
                            value={formik.values.controlNumber}
                            error={
                              formik.touched.controlNumber &&
                              formik.errors.controlNumber
                                ? formik.errors.controlNumber
                                : ""
                            }
                            onChange={formik.handleChange}
                          />
                        </div>
                      </div>

                      <div>
                        <FormFileUpload
                          error={
                            formik.touched.file && formik.errors.file
                              ? formik.errors.file
                              : ""
                          }
                          description="El archivo debe ser formato PDF o imagen"
                          onSelectFile={(file) =>
                            formik.setFieldValue("file", file)
                          }
                          className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50"
                        />
                      </div>

                      <div className="flex w-full justify-between mt-6">
                        <SecondaryButton
                          onClick={() => setOpenAttachmentModal(false)}
                          className="w-40"
                          type="button"
                        >
                          Cancelar
                        </SecondaryButton>

                        <PrimaryButton className="w-40" type="submit">
                          Actualizar
                        </PrimaryButton>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </Modal>

            <Modal
              openModal={openDeleteModal}
              setOpenModal={setOpenDeleteModal}
            >
              <div className="flex flex-col gap-4 max-w-[20rem]">
                <p className="text-xl text-center">
                  ¿Está seguro que desea eliminar el adjunto del reporte{" "}
                  <span className="font-semibold text-indigo-600">
                    {pdocument.documentNumber ?? pdocument.reportID}
                  </span>
                  ?
                </p>

                <div className="flex w-full justify-between mt-6 gap-4">
                  <SecondaryButton
                    onClick={() => setOpenDeleteModal(false)}
                    className="w-40"
                    type="button"
                  >
                    Cancelar
                  </SecondaryButton>

                  <PrimaryButton
                    onClick={handleAttachmentDelete}
                    className="w-40"
                    type="button"
                  >
                    Eliminar
                  </PrimaryButton>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </td>

      <td className="px-4 py-4 text-xs text-gray-500 font-semibold">
        {new Date(pdocument.reportToDate).toLocaleDateString("es-VE", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })}
      </td>

      <td className="px-4 py-4 text-xs text-right text-gray-500 font-semibold truncate">
        {currencyExchangeText(pdocument.totalAmountBaseCurr, exchanges, "USD")}
      </td>

      <td
        className={classNames(
          "px-4 py-4 text-xs text-right text-gray-500 font-semibold truncate",
          isActive && "hidden"
        )}
      >
        {pdocumentStatusFormat(pdocument.statusID)}
      </td>

      <td
        className={classNames(
          "w-[7rem]",
          (pdocument.documentTypeID !== PDocumentType.ODF ||
            !pdocument.attachmentID) &&
            pdocument.statusID !== PDocumentStatus.TO_APPROVE &&
            "hidden"
        )}
      >
        <div className="flex items-center gap-2 ml-4">
          <div
            className="rounded-full p-2 bg-red-100 cursor-pointer hover:bg-red-200"
            onClick={(e) => {
              e.stopPropagation();
              setOpenDeleteModal(true);
            }}
          >
            <TrashIcon className="w-5 h-5 text-red-600" />
          </div>

          <div
            className="rounded-full p-2 bg-indigo-100 cursor-pointer hover:bg-indigo-200"
            onClick={(e) => {
              e.stopPropagation();
              setOpenEditModal(true);
            }}
          >
            <PencilIcon className="w-5 h-5 text-blue-600" />
          </div>
        </div>
      </td>
    </tr>
  );
};
