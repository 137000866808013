import api from "./api";
import axios from "axios";
import { DestinationDto } from "../interfaces/Dtos";
import { TaxIdentificationTypeInterface } from "../interfaces/TaxIdentificationTypeInterface";
import {
  Nullable,
  AccountInterface,
  ResponseInterface,
  DestinationInterface,
  WhiteLocationInterface,
  AccountRequestInterface,
  FavAndHistoricalDestinationListInterface,
} from "../interfaces";
import { AccountBlocked } from "../components/Account/AccountBlocked";

const baseURLAPI = process.env.REACT_APP_API_HOST;

export const getAccount = async (
  accountId: string
): Promise<Nullable<AccountInterface>> => {
  //const uri = `${baseURLJsonServer}/accounts?id=${accountId}`;
  if (!accountId) {
    return null;
  }
  const uri = `${baseURLAPI}/Account/GetAccount?accountId=${accountId}`;

  try {
    const response = await api.get(uri);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {}

  return null;
};

export const getAccountByIdentification = async (
  abreviationName: string,
  identificationNumber: string
): Promise<ResponseInterface<AccountInterface>> => {
  if (!abreviationName || !identificationNumber) {
    return {
      model: null,
      didError: true,
      message: "Invalid parameters",
      errorMessage: "Invalid parameters",
    };
  }
  const uri = `${baseURLAPI}/GetAccount?abreviationName=${abreviationName}&identificationNumber=${identificationNumber}`;

  try {
    const response = await api.get(uri);

    return response.data;
  } catch (error) {
    const response = (error as any)?.response;

    if (response) {
      return response.data;
    }

    return {
      model: null,
      didError: true,
      message: `Error: ${error}`,
      errorMessage: "Application error 1",
    };
  }
};

export const saveAccount = async (
  account: AccountRequestInterface
): Promise<ResponseInterface<AccountInterface>> => {
  account.creationDate = new Date().toISOString();
  const body = JSON.stringify(account);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const uri = `${baseURLAPI}/Account`;

  try {
    const response = await api.post(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token,
    });

    if (response.status === 200) {
      return response.data;
    }
    return {
      model: null,
      didError: true,
      message: `Server status: ${response.status}`,
      errorMessage: "Server error",
    };
  } catch (error) {
    return {
      model: null,
      didError: true,
      message: `Error: ${error}`,
      errorMessage: "Application error 2",
    };
  }
};

export const updateAccount = async (
  account: AccountRequestInterface
): Promise<ResponseInterface<AccountInterface>> => {
  const body = JSON.stringify(account);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const uri = `${baseURLAPI}/Account`;

  try {
    const response = await api.put(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token,
    });

    if (response.status === 200) {
      return response.data;
    }
    return {
      model: null,
      didError: true,
      message: `Server status: ${response.status}`,
      errorMessage: "Server error",
    };
  } catch (error) {
    return {
      model: null,
      didError: true,
      message: `Error: ${error}`,
      errorMessage: "Application error 3",
    };
  }
};

export const getFilteredAccounts = async (
  criteria: string
): Promise<ResponseInterface<AccountInterface[]>> => {
  const uri = `${baseURLAPI}/Account?idStr=${criteria}&maxRows=20&withAgreementAuths=true`;
  try {
    const response = await api.get(uri);

    if (response.status === 200) {
      return {
        model: response.data,
        didError: false,
        message: "",
        errorMessage: "",
      };
    }
  } catch (error) {
    return (error as any)?.response?.data;
  }

  return {
    model: null,
    didError: true,
    message: "Hubo un error en la búsqueda de clientes.",
    errorMessage: "Hubo un error en la búsqueda de clientes.",
  };
};

export const getFavoriteAndHistoricalLocations = async (
  accountId: string,
  taxIdentificationTypes: TaxIdentificationTypeInterface[],
  search: string = ""
): Promise<ResponseInterface<FavAndHistoricalDestinationListInterface>> => {
  const uri = `${baseURLAPI}/DestinationsFavRecent?AccountID=${accountId}&SearchInput=${search}`;

  try {
    const response = await api.get(uri);

    if (
      response.status === 200 &&
      !response.data.didError &&
      !!response.data.model
    ) {
      const favoriteDestination = response.data.model;

      // Map the objet to a FavAndHistoricalDestinationListInterface
      return {
        message: "",
        didError: false,
        errorMessage: "",
        model: {
          accountID: favoriteDestination.accountID,
          favoriteDestinations: favoriteDestination.favoriteDestination.map(
            (destination: DestinationDto) =>
              mapToDestinationInterface(destination, taxIdentificationTypes)
          ),
          historicalDestinations: favoriteDestination.salesDestination.map(
            (destination: DestinationDto) =>
              mapToDestinationInterface(destination, taxIdentificationTypes)
          ),
        },
      };
    } else if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return (error as any)?.response?.data;
  }

  return {
    model: null,
    didError: true,
    message: "Hubo un error obteniendo el historial de búsquedas.",
    errorMessage: "Hubo un error obteniendo el historial de búsquedas.",
  };
};

export const getAccountWhitelist = async (
  taxIdentificationTypes: TaxIdentificationTypeInterface[],
  accountId?: string,
  agreementID?: string,
  isLocked?: boolean
): Promise<ResponseInterface<WhiteLocationInterface>> => {
  const uri = `${baseURLAPI}/Agreement/Location?`;
  let first = true;
  if (accountId) {
    uri.concat(`AccountID=${accountId}`);
    first = false;
  }
  if (agreementID) {
    if (first) {
      uri.concat(`AgreementID=${agreementID}`);
    } else {
      uri.concat(`&AgreementID=${agreementID}`);
    }
    first = false;
  }
  if (isLocked) {
    if (first) {
      uri.concat(`IsLocked=${isLocked}`);
    } else {
      uri.concat(`&IsLocked=${isLocked}`);
    }
  }

  try {
    const response = await api.get(uri);

    if (
      response.status === 200 &&
      !response.data.didError &&
      !!response.data.model
    ) {
      const whiteLocations = response.data.model;

      // Map the objet to a AgreementLocationsListInterface
      return {
        message: "",
        didError: false,
        errorMessage: "",
        model: {
          accountID: whiteLocations.accountID,
          consigneeDestinationList: whiteLocations.consigneeDestinationList.map(
            (destination: DestinationDto) =>
              mapToDestinationInterface(destination, taxIdentificationTypes)
          ),
        },
      };
    } else if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return (error as any)?.response?.data;
  }

  return {
    model: null,
    didError: true,
    message: "Hubo un error obteniendo las cuentas permitidas.",
    errorMessage: "Hubo un error obteniendo las cuentas permitidas.",
  };
};

// Method to map DestinationDto to DestinationInterface
export const mapToDestinationInterface = (
  destination: DestinationDto,
  taxIdTypes: TaxIdentificationTypeInterface[]
): DestinationInterface => {
  const taxIdType = taxIdTypes.find(
    (taxIdType) =>
      taxIdType.taxIdentificationTypeId === destination.taxIdentificationTypeID
  );
  return {
    client: {
      id: destination.accountID,
      accountCode: destination.accountCode,
      taxIdentificationTypeID: destination.taxIdentificationTypeID,
      identificationNumber: destination.identificationNumber,
      accountFullName: destination.accountFullName,
      taxIdentificationTypeCode: taxIdType?.taxIdentificationTypeCode ?? "",
      abreviationName: taxIdType?.abreviationName ?? "",
      listAccountPhone: destination.accountPhoneList.map((phone) => ({
        phoneID: phone.accountPhoneID,
        accountID: destination.accountID,
        countryID: phone.accountPhoneCountryID,
        phoneNumber: phone.accountPhoneNumber,
        countryPhoneAccessCode: phone.accountCountryPhoneAccessCode,
        phoneTypeID: phone.accountPhoneTypeID,
      })),
      listAccountEmail: [],
      listAuthorizingAccount: [],
      creationDate: "",
      creationUser: "",
    },
    location: {
      name: destination.addressName,
      address:
        destination.addressLine1 +
        (destination.addressLine2 !== null
          ? " " + destination.addressLine2
          : ""),
      reference: destination.addressLandMark,
      postalCode: destination.postalCode,
      coordinates: {
        lat: destination.latitude ?? 0,
        lng: destination.longitude ?? 0,
      },
    },
    businessUnit: {
      id: destination.businessUnit.buid.toString(),
      name: destination.businessUnit.buName,
      code: destination.businessUnit.buCode,
      location: {
        name: destination.businessUnit.buAreaName ?? "",
        address: destination.businessUnit.address ?? "",
        reference: destination.businessUnit.reference ?? "",
        postalCode: destination.businessUnit.postalCode ?? "",
        coordinates: {
          lat: !!destination.businessUnit.latitude
            ? parseFloat(destination.businessUnit.latitude)
            : 0,
          lng: !!destination.businessUnit.longitude
            ? parseFloat(destination.businessUnit.longitude)
            : 0,
        },
      },
      polygonList: [],
    },
    homeDelivery: destination.isHomeDelivery,
  };
};

export const verifyAccountBlocked = async (
  accountID: string,
  serviceID: number,
  paymentModeID: number,
  buCode: string
): Promise<ResponseInterface<AccountBlocked[]>> => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const uri = `${baseURLAPI}/Account/VerifyAccountBlocked?AccountID=${accountID}&ServiceID=${serviceID}&PaymentModeID=${paymentModeID}&BUCode=${buCode}`;

  try {
    const response = await api.get(uri, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token,
    });

    if (response.status === 200) {
      return response.data;
    }
    return {
      model: null,
      didError: true,
      message: "",
      errorMessage: "Hubo un error al verificar la lista negra de los cupones",
    };
  } catch (error) {
    return (error as any)?.response?.data;
  }
};
