import { FC, useEffect, useState } from "react";
import Modal from "../Modal";
import { FormikProps } from "formik";
import ChangeModal from "./ChangeModal";
import { useAppSelector } from "../../store/hooks";
import { PrimaryButton, SecondaryButton } from "../Buttons";
import { alertService, getBankList, listBankBC } from "../../services";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import {
  Currency,
  currencyExchange,
  formatGooglePhone,
  currencyExchangeText,
  useCurrencyExchanges,
} from "../../utils";
import {
  AccountInterface,
  Banco,
  Nullable,
  PaymentInterface,
  PaymentMethodEnum,
  PaymentMethodInterface,
  PaymentStatusEnum,
} from "../../interfaces";

export interface PaymentFormValues {
  amount: number;
  clientIdentifier: string;
  paymentMethod: PaymentMethodInterface;
  bank: Banco;
  phone: string;
  reference: string;
  currency: Currency;
  destBankID: Nullable<number>;
}

interface PaymentConfirmModalProps {
  formik: FormikProps<PaymentFormValues>;
  owner: AccountInterface;
  remaining: number;
  payments: PaymentInterface[];
  detailView?: boolean;
  availableChange: number;
  paymentConfirmationModalClose: boolean;
  setPaymentConfirmationModalClose: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  onSubmit: (payment: PaymentInterface) => void;
}

const PaymentChange: FC<PaymentConfirmModalProps> = ({
  formik,
  owner,
  remaining,
  payments,
  detailView = false,
  availableChange,
  paymentConfirmationModalClose,
  setPaymentConfirmationModalClose,
  onSubmit,
}) => {
  const paymentMethods = useAppSelector(
    (state) => state.user.paymentMethods ?? []
  );
  const exchanges = useCurrencyExchanges();
  const [changeModal, setChangeModal] = useState(false);
  const [pendingChange, setPendingChange] = useState(false);
  const [firstOpenModal, setFirstOpenModal] = useState(true);
  const [reintegrationModal, setReintegrationModal] = useState(false);
  const [reintegrationConfirmationModal, setReintegrationConfirmationModal] =
    useState(false);
  const [isNatural, setIsNatural] = useState(true);
  const [b2pBank, setB2pBank] = useState<Banco[]>();
  const [bankList, setBankList] = useState<Banco[]>([]);

  const handleReintegration = async () => {
    formik.setValues({
      ...formik.values,
      paymentMethod: {
        ...paymentMethods.find(
          (method) => method.paymentMethodID === PaymentMethodEnum.REINTEGRO
        )!,
        bankID: 0,
      },
    });
  };

  const handleSubmitReintegration = async () => {
    const reintegrationMethod = paymentMethods.find(
      (e) => e.paymentMethodID === PaymentMethodEnum.REINTEGRO
    );
    if (!reintegrationMethod) {
      alertService.error("Método de reintegro no habilitado");
      return;
    }
    formik.setFieldValue("paymentMethod", reintegrationMethod);
    const payment = {
      status: PaymentStatusEnum.PENDING,
      isRetention: false,
      amount: -remaining,
      clientIdentifier: formik.values.clientIdentifier,
      paymentMethod: formik.values.paymentMethod,
      bank: "0",
      destBankID: undefined,
      phone: formik.values.phone,
      reference: "",
      igtfAmount: 0,
      igtfPercentage: 0,
    };

    onSubmit(payment);
    setReintegrationConfirmationModal(false);
  };

  useEffect(() => {
    // Get b2p bank list
    const listBank = async () => {
      const res: Banco[] = await listBankBC("b2p");
      if (res !== null) {
        setB2pBank(res);
      }
    };
    const getBanks = async () => {
      const res = await getBankList();
      if (res.model !== null && !res.didError) {
        setBankList(res.model);
      }
    };

    listBank();
    getBanks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      remaining < -0.1 &&
      firstOpenModal &&
      !detailView &&
      paymentConfirmationModalClose
    ) {
      if (
        currencyExchange(availableChange, exchanges, "BS", "USD") <
          -remaining ||
        !isNatural
      )
        setReintegrationModal(true);
      else setChangeModal(true);
      setPaymentConfirmationModalClose(false);
      setFirstOpenModal(false);
    }

    // Set amount to pay in Bs
    let change = +currencyExchange(-remaining, exchanges, "BS").toFixed(2);
    formik.setFieldValue("amount", change);

    // Update formik when formik values change will cause an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    remaining,
    firstOpenModal,
    paymentConfirmationModalClose,
    availableChange,
  ]);

  // Set client identifier and phone
  useEffect(() => {
    formik.setFieldValue(
      "clientIdentifier",
      // slice last char on string
      `${owner?.abreviationName?.[0]}${owner?.identificationNumber}` ?? ""
    );
    formik.setFieldValue(
      "phone",
      owner?.listAccountPhone?.[0]?.phoneNumber
        ? formatGooglePhone(owner?.listAccountPhone?.[0]?.phoneNumber)
        : ""
    );

    // Check if owner is natural or legal person
    if (owner?.abreviationName !== "V-" && owner?.abreviationName !== "E-")
      setIsNatural(false);
    else setIsNatural(true);

    // Update formik when formik values change will cause an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [owner]);

  useEffect(() => {
    payments.forEach((payment) => {
      payment.status === PaymentStatusEnum.PENDING &&
        payment.paymentMethod.paymentMethodID === PaymentMethodEnum.REINTEGRO &&
        setPendingChange(() => true);
    });
  }, [payments]);

  return (
    <>
      <div className="flex flex-row items-center justify-end">
        <div className="flex flex-row items-start justify-end">
          {pendingChange ? (
            <p className="text-sm font-semibold text-gray-600">
              Hay un reintegro pendiente por aprobar
            </p>
          ) : (
            <>
              <h2 className="text-md font-semibold px-2 items-center">
                Cambio pendiente:
              </h2>
              <div className="flex flex-row justify-start pt-0.5">
                <p className="text-sm font-semibold text-gray-600 truncate place-self-center">
                  {currencyExchangeText(-remaining, exchanges, "BS")}
                </p>
                <p className="text-xs text-gray-400 place-self-center ml-1">
                  {currencyExchangeText(-remaining, exchanges, "USD")}
                </p>
              </div>
            </>
          )}
        </div>
        <PrimaryButton
          className="ml-4"
          disabled={pendingChange}
          onClick={() => {
            if (
              currencyExchange(availableChange, exchanges, "BS", "USD") <
                -remaining ||
              !isNatural
            )
              setReintegrationModal(true);
            else setChangeModal(true);
          }}
        >
          Dar cambio
        </PrimaryButton>
      </div>

      <Modal
        openModal={reintegrationModal}
        setOpenModal={setReintegrationModal}
      >
        <div
          className="flex flex-col items-center justify-center"
          style={{ maxWidth: "20rem" }}
        >
          <div className="flex flex-col items-center justify-center w-full">
            <ExclamationTriangleIcon className="w-32 h-32" />
          </div>
          <p className="mt-2 text-lg text-center text-gray-700">
            No hay saldo disponible para dar cambio por pago móvil o el cliente
            no es elegible para el mismo. ¿Quiere iniciar un proceso de
            reintegro por Casa Matriz?
          </p>
          <div className="mt-4 flex flex-row justify-center gap-12">
            <SecondaryButton
              className="px-4"
              onClick={() => {
                setReintegrationModal(false);
                setChangeModal(true);
              }}
            >
              Más opciones
            </SecondaryButton>

            <PrimaryButton
              className="px-4"
              onClick={() => {
                setReintegrationConfirmationModal(true);
                setReintegrationModal(false);
              }}
            >
              Si
            </PrimaryButton>
          </div>
        </div>
      </Modal>

      <Modal
        openModal={reintegrationConfirmationModal}
        setOpenModal={setReintegrationConfirmationModal}
      >
        <div
          className="flex flex-col items-center justify-center"
          style={{ maxWidth: "20rem" }}
        >
          <div className="flex flex-col items-center justify-center w-full">
            <ExclamationTriangleIcon className="w-32 h-32" />
          </div>
          <p className="mt-2 text-lg text-center text-gray-700">
            ¿Está seguro que quiere iniciar un proceso de solicitud de
            reintegro?
          </p>
          <div className="mt-4 flex flex-row justify-center gap-12">
            <SecondaryButton
              className="px-4"
              type="button"
              onClick={async () => {
                await handleReintegration();
                setReintegrationConfirmationModal(false);
              }}
            >
              Cancelar
            </SecondaryButton>

            <PrimaryButton
              className="px-4"
              type="button"
              onClick={handleSubmitReintegration}
            >
              Aceptar
            </PrimaryButton>
          </div>
        </div>
      </Modal>

      <ChangeModal
        formik={formik}
        remaining={remaining}
        b2pBank={b2pBank}
        bankList={bankList}
        openModal={changeModal}
        setOpenModal={setChangeModal}
        setOpenReintegrationModal={setReintegrationConfirmationModal}
        payments={payments}
        availableChange={availableChange}
        isNatural={isNatural}
      />
    </>
  );
};

export default PaymentChange;
