import React, { useMemo, FC, useEffect, useState } from "react";
import Fuse from "fuse.js";
import * as Yup from "yup";
import classNames from "classnames";
import FormLabel from "../FormLabel";
import { AddButton, PrimaryButton, SecondaryButton } from "../Buttons";
import { Formik, FormikProps } from "formik";
import { useAppSelector } from "../../store/hooks";
import { FormTextInput, FormSearch } from "../FormFields";
import { formatFloat } from "../../utils";
import {
  PieceInterface,
  ShipmentService,
  PieceCategoryInterface,
} from "../../interfaces";

interface PieceFormValues {
  [key: string]: string | number;
}
interface FormInterface {
  formik: FormikProps<PieceFormValues>;
  type: ShipmentService;
  pieceCategories: PieceCategoryInterface[];
  pieces?: PieceInterface[];
  editMode?: boolean;
  maxSumWeight: number;
  handleNumberChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    formik: FormikProps<any>,
    allowFloats?: boolean
  ) => void;
  setEditMode?: (value: boolean) => void;
}
const DocumentTypeName = "Correspondencias";

const Form: FC<FormInterface> = ({
  formik,
  type,
  pieceCategories,
  pieces,
  editMode,
  maxSumWeight,
  handleNumberChange,
  setEditMode = () => {},
}) => {
  const categories = useAppSelector((state) => state.inmutable.pieceCategories);

  const [search, setSearch] = useState<string>("");
  const fuse = useMemo(
    () =>
      new Fuse(categories, {
        keys: ["descriptionTags", "name"],
        threshold: 0.2,
        ignoreLocation: true,
      }),
    [categories]
  );
  const filteredCategories = fuse.search(search, { limit: 5 });

  useEffect(() => {
    if (type === ShipmentService.DOCUMENT) {
      // Set category to Documents
      formik.setFieldValue(
        "category",
        pieceCategories.find((d) => d.name === DocumentTypeName)?.name
      );

      // Set dimentions
      formik.setFieldValue("height", 30);
      formik.setFieldValue("width", 23);
      formik.setFieldValue("length", 1);
      formik.setFieldValue("amount", 1);
    } else if (!formik.values.category) {
      setSearch("");
      formik.setFieldValue("height", "");
      formik.setFieldValue("width", "");
      formik.setFieldValue("length", "");
      formik.setFieldValue("amount", 1);
    }

    // Only the type is needed to change the category to "Documentos"
    // if the type is "document" or to deselect it if the type it is "regular"
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, formik.values.category]);

  return (
    <form onSubmit={formik.handleSubmit} className="flex h-full flex-col gap-6">
      {/* Header */}
      <div className="flex items-start">
        <p className="font-semibold leading-6 text-gray-600">
          Complete los datos de la pieza:
        </p>
      </div>

      {/* Body */}
      <div className="flex flex-col gap-4">
        <div className="flex flex-1 flex-col">
          <div className="relative w-full">
            <FormSearch
              value={search}
              label="Categorías"
              name="category"
              placeholder="Buscar categoría..."
              disabled={type === ShipmentService.DOCUMENT}
              onChange={(e) => setSearch(e.target.value)}
              options={
                search.length > 0
                  ? filteredCategories.map((r) => r.item.name).length < 1 &&
                    search.length > 0
                    ? ["Otras Categorías"]
                    : filteredCategories.map((r) => r.item.name)
                  : categories.map((r) => r.name)
              }
              onSelectOption={async (category) => {
                setSearch(category);
                formik.setFieldValue("category", category);
              }}
              RenderOption={({ option }) => <p> {option} </p>}
              onChangeFocus={(focus) => {
                if (!focus && formik.values.category)
                  setSearch(formik.values.category as string);
              }}
              error={
                formik.touched.category && formik.errors.category
                  ? formik.errors.category
                  : undefined
              }
            />
          </div>
        </div>

        <div>
          <FormTextInput
            name="weight"
            label="Peso en kg."
            value={formik.values.weight}
            disabled={!formik.values.category}
            error={
              formik.touched.weight && formik.errors.weight
                ? formik.errors.weight
                : undefined
            }
            onChange={(e) => handleNumberChange(e, formik, true)}
            onBlur={formik.handleBlur}
          />
        </div>

        <div
          className={classNames(type === ShipmentService.DOCUMENT && "hidden")}
        >
          <FormLabel fieldName="Dimensiones en cm." />
          <div className="flex flex-1 gap-4 flex-row">
            <div className="flex flex-1 flex-col">
              <FormTextInput
                name="height"
                type="number"
                label="Alto"
                value={formik.values.height}
                disabled={
                  !formik.values.category || type === ShipmentService.DOCUMENT
                }
                error={
                  formik.touched.height && formik.errors.height
                    ? formik.errors.height
                    : undefined
                }
                onChange={(e) => handleNumberChange(e, formik, false)}
                onBlur={formik.handleBlur}
              />
            </div>

            <div className="flex flex-1 flex-col">
              <FormTextInput
                name="width"
                type="number"
                label="Ancho"
                value={formik.values.width}
                disabled={
                  !formik.values.category || type === ShipmentService.DOCUMENT
                }
                error={
                  formik.touched.width && formik.errors.width
                    ? formik.errors.width
                    : undefined
                }
                onChange={(e) => handleNumberChange(e, formik, false)}
                onBlur={formik.handleBlur}
              />
            </div>

            <div className="flex flex-1 flex-col">
              <FormTextInput
                name="length"
                type="number"
                label="Largo"
                value={formik.values.length}
                disabled={
                  !formik.values.category || type === ShipmentService.DOCUMENT
                }
                error={
                  formik.touched.length && formik.errors.length
                    ? formik.errors.length
                    : undefined
                }
                onChange={(e) => handleNumberChange(e, formik, false)}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="flex justify-between sm:items-end w-full gap-4 flex-col sm:flex-row">
        <div>
          <FormTextInput
            name="amount"
            type="number"
            label="Cantidad"
            value={formik.values.amount}
            disabled={
              !formik.values.category ||
              type === ShipmentService.DOCUMENT ||
              editMode
            }
            error={
              formik.touched.amount && formik.errors.amount
                ? formik.errors.amount
                : undefined
            }
            onChange={(e) => handleNumberChange(e, formik, false)}
            onBlur={formik.handleBlur}
          />
        </div>

        <div className={classNames(editMode && "hidden")}>
          <AddButton
            type="submit"
            disabled={
              !formik.isValid ||
              pieces!.reduce((r, p) => r + p.weight, 0) >= maxSumWeight ||
              !Object.values(formik.values).some((value) => !!value) ||
              (type === ShipmentService.DOCUMENT &&
                pieces &&
                pieces?.length > 0)
            }
          />
        </div>

        <SecondaryButton
          type="button"
          className={classNames(!editMode && "hidden")}
          onClick={() => setEditMode(false)}
        >
          Cancelar
        </SecondaryButton>

        <PrimaryButton
          type="submit"
          className={classNames(!editMode && "hidden")}
        >
          Guardar
        </PrimaryButton>
      </div>
    </form>
  );
};

interface PieceFormProps {
  type: ShipmentService;
  initialValues: PieceFormValues;
  pieceCategories: PieceCategoryInterface[];
  pieces?: PieceInterface[];
  applyMinValue?: boolean;
  editMode?: boolean;
  editIndex?: number;
  maxSumWeight?: number;
  onSubmit: (prop: PieceInterface, amount: number) => void;
  setEditMode?: (value: boolean) => void;
}
const PieceForm: FC<PieceFormProps> = ({
  type,
  initialValues,
  pieceCategories,
  pieces,
  editMode,
  editIndex,
  maxSumWeight = 999.99,
  onSubmit,
  setEditMode,
}) => {
  // Validations with Yup for Formik form
  const validationSchema = Yup.object().shape({
    category: Yup.string().required("Este campo es requerido"),
    weight: Yup.number()
      .required("Este campo es requerido")
      .min(0.01, "Debe ser mayor a 0")
      .when("category", {
        is: (val: string) =>
          val === DocumentTypeName && type === ShipmentService.DOCUMENT,
        then: () =>
          Yup.number().max(
            type === ShipmentService.DOCUMENT ? 0.5 : Number.MAX_VALUE,
            "Las piezas no pueden superar los 500 gramos"
          ),
      })
      .test(
        "maxSumWeight",
        `La suma de las piezas puede superar los ${maxSumWeight} Kg`,
        function (value) {
          const { amount } = this.parent as PieceFormValues;

          if (editMode && editIndex !== undefined) {
            const { weight: oldWeight } = pieces![editIndex];
            return (
              +(amount ?? 0) * value - oldWeight <=
              maxSumWeight - pieces!.reduce((r, p) => r + p.weight, 0)
            );
          }

          return (
            +(amount ?? 0) * value <=
            maxSumWeight - pieces!.reduce((r, p) => r + p.weight, 0)
          );
        }
      ),
    height: Yup.number()
      .required("Este campo es requerido")
      .min(0.01, "Debe ser mayor a 0")
      .max(300, "No puede superar los 300 cm"),
    width: Yup.number()
      .required("Este campo es requerido")
      .min(0.01, "Debe ser mayor a 0")
      .max(300, "No puede superar los 300 cm"),
    length: Yup.number()
      .required("Este campo es requerido")
      .min(0.01, "Debe ser mayor a 0")
      .max(300, "No puede superar los 300 cm"),
    amount: Yup.number()
      .required("Este campo es requerido")
      .min(1, "Debe ser mayor a 0")
      .test(
        "maxSumWeight",
        `La suma de las piezas puede superar los ${maxSumWeight} Kg`,
        function (value) {
          const { weight } = this.parent as PieceFormValues;

          if (editMode && editIndex !== undefined) {
            const { weight: oldWeight } = pieces![editIndex];
            return (
              value * +(weight ?? 0) - oldWeight <=
              maxSumWeight - pieces!.reduce((r, p) => r + p.weight, 0)
            );
          }

          return (
            value * +(weight ?? 0) <=
            maxSumWeight - pieces!.reduce((r, p) => r + p.weight, 0)
          );
        }
      ),
  });

  const handleNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    formik: FormikProps<any>,
    allowFloats = true
  ) => {
    if (event.target.value.length > 1) {
      event.target.value = event.target.value.replace(/^0+/, "");
    }
    const setValue = (value: string) => {
      event.target.value = value;
    };

    if (!allowFloats) {
      event.target.value = event.target.value.replace(/[^0-9]/g, "");
    } else if (!formatFloat(event.target.value, setValue)) {
      return;
    }

    formik.handleChange(event);
  };

  const form = useMemo(() => {
    // Create key with all initialValues values
    const initialValuesKey = `${Object.values(initialValues).join("-")}`;

    return (
      <Formik
        key={initialValuesKey}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          onSubmit(
            {
              category: pieceCategories.find(
                (category) => category.name === values.category
              )!,
              value: 0,
              weight: +values.weight,
              height: +values.height,
              width: +values.width,
              length: +values.length,
            },
            +values.amount
          );
          resetForm();
        }}
      >
        {(formik) => (
          <Form
            formik={formik}
            type={type}
            pieceCategories={pieceCategories}
            pieces={pieces}
            editMode={editMode}
            maxSumWeight={maxSumWeight}
            setEditMode={setEditMode}
            handleNumberChange={handleNumberChange}
          />
        )}
      </Formik>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    initialValues,
    pieceCategories,
    type,
    editMode,
    onSubmit,
    validationSchema,
  ]);

  return form;
};

export default PieceForm;
