import api from "./api";
import axios from "axios";
import {
  mapBUDtoToBUInterface,
  mapBusinessUnitDtoListToBusinessUnitInterface,
} from "../utils";
import { DailyStoreOperationDTO } from "../interfaces/Dtos/DailyStoreOperationDTO";
import {
  Nullable,
  ResponseInterface,
  BusinessUnitInterface,
  Banco,
} from "../interfaces";
import { DailyStoreCloseDTO } from "../interfaces/Dtos/DailyStoreCloseDTO";
import { BankAccountDTO } from "../interfaces/Dtos/BankAccountDTO";
import { DashboardDataDTO } from "../interfaces/Dtos/DashboardDataDTO";
import { BankListDTO } from "../interfaces/Dtos/BankListDTO";
import { BUCreditDTO } from "../interfaces/Dtos/BUCreditDTO";
import { PaymentModeDto } from "../interfaces/Dtos";

const baseURL = process.env.REACT_APP_API_HOST;

export const getBusinessUnits = async (): Promise<
  Nullable<BusinessUnitInterface[]>
> => {
  const uri = `${baseURL}/BusinessUnit/BusinessUnitV2?status=1&withPolygonsList=false&IsNational=false`;

  try {
    const response = await api.get(uri);

    if (response.status === 200) {
      const storeDto = response.data;
      return mapBusinessUnitDtoListToBusinessUnitInterface(storeDto);
    }
  } catch (error) {}

  return null;
};
// Method to get the Store serving the destination specific address (longitude, latitude)
export const getBusinessUnitByTerritory = async (
  longitude: number,
  latitude: number
): Promise<ResponseInterface<BusinessUnitInterface>> => {
  const uri = `${baseURL}/BusinessUnit/GetBusinessUnitByTerritory?longitude=${longitude}&latitude=${latitude}`;

  try {
    const response = await api.get(uri);

    if (response.status === 200) {
      const storeDto = response.data.model;
      if (storeDto != null) {
        response.data.model = mapBUDtoToBUInterface(storeDto);
      }
      return response.data;
    }
  } catch (error) {}

  return {
    model: null,
    didError: true,
    message: "No se pudo obtener la tienda de cobertura del punto especificado",
    errorMessage:
      "No se pudo obtener la tienda de cobertura del punto especificado",
  };
};

export const getAvailableChange = async (
  buCode: string
): Promise<ResponseInterface<number>> => {
  const uri = `${baseURL}/BusinessUnit/AvailableChange?BUCode=${buCode}`;

  try {
    const response = await api.get(uri);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {}

  return {
    model: null,
    didError: true,
    message: "No se pudo obtener el vuelto disponible",
    errorMessage: "No se pudo obtener el vuelto disponible",
  };
};

export const addOperation = async (
  request: DailyStoreOperationDTO
): Promise<ResponseInterface<number>> => {
  const uri = `${baseURL}/BusinessUnit/DailyOperation`;

  const body = JSON.stringify({
    PaymentID: request.paymentID,
    PaymentDetailID: request.paymentDetailID,
    OperationTypeID: request.operationTypeID,
    TransactionTypeID: request.transactionTypeID,
    BUCode: request.buCode,
    PaymentMethodID: request.paymentMethodID,
    CurrencyID: request.currencyID,
    OperationDate: request.operationDate,
    Income: request.income,
    Expenses: request.expenses,
    BankID: request.bankID,
    BankAccountID: request.bankAccountID,
    ReferenceNum: request.referenceNum,
    OperationStatusID: request.operationStatusID,
    AttachmentID: request.attachmentID,
    Applies: request.applied,
    CreationUser: request.creationUser,
  });
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  try {
    const response = await api.post(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token,
    });

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {}

  return {
    model: null,
    didError: true,
    message: "No se pudo agregar la operación",
    errorMessage: "No se pudo agregar la operación",
  };
};

export const getDailyOperations = async (
  buCode: string
): Promise<ResponseInterface<DailyStoreOperationDTO[]>> => {
  const uri = `${baseURL}/BusinessUnit/DailyOperation?BUCode=${buCode}`;

  try {
    const response = await api.get(uri);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {}

  return {
    model: null,
    didError: true,
    message: "No se pudieron obtener las operaciones diarias",
    errorMessage: "No se pudieron obtener las operaciones diarias",
  };
};

export const getDailyClosures = async (
  buCode: string
): Promise<ResponseInterface<DailyStoreCloseDTO[]>> => {
  const uri = `${baseURL}/BusinessUnit/DailyClose?BUCode=${buCode}`;

  try {
    const response = await api.get(uri);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {}

  return {
    model: null,
    didError: true,
    message: "No se pudieron obtener los cierres diarias",
    errorMessage: "No se pudieron obtener los cierres diarias",
  };
};

export const getBankAccounts = async (): Promise<
  ResponseInterface<BankAccountDTO[]>
> => {
  const uri = `${baseURL}/BusinessUnit/BankAccounts`;

  try {
    const response = await api.get(uri);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {}

  return {
    model: null,
    didError: true,
    message: "No se pudieron obtener las cuentas bancarias",
    errorMessage: "No se pudieron obtener las cuentas bancarias",
  };
};

export const getBankList = async (): Promise<ResponseInterface<Banco[]>> => {
  const uri = `${baseURL}/BusinessUnit/BankList`;

  try {
    const response = await api.get(uri);

    if (response.status === 200) {
      response.data.model = response.data.model.map((bank: BankListDTO) => ({
        id: bank.bankID,
        codigo: bank.bankCode,
        descripcion: bank.shortName,
      }));
      return response.data;
    }
  } catch (error) {}

  return {
    model: null,
    didError: true,
    message: "No se pudieron obtener los bancos disponibles",
    errorMessage: "No se pudieron obtener los bancos disponibles",
  };
};

export const getBusinessDashboardData = async (
  buCode: string
): Promise<ResponseInterface<DashboardDataDTO>> => {
  const uri = `${baseURL}/BusinessUnit/GetDashboardData?BUCode=${buCode}`;

  try {
    const response = await api.get(uri);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {}

  return {
    model: null,
    didError: true,
    message: "No se pudieron obtener los datos del Dashboard",
    errorMessage: "No se pudieron obtener los datos del Dashboard",
  };
};

export const getBUCredit = async (
  buCode: string
): Promise<ResponseInterface<BUCreditDTO>> => {
  const uri = `${baseURL}/BusinessUnit/GetBUCredit?BUCode=${buCode}`;

  try {
    const response = await api.get(uri);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {}

  return {
    model: null,
    didError: true,
    message: "No se pudieron obtener los datos de Crédito Taquilla",
    errorMessage: "No se pudieron obtener los datos de Crédito Taquilla",
  };
};

export const getLockBUPaymentModes = async (
  buCode: string
): Promise<ResponseInterface<PaymentModeDto[]>> => {
  const uri = `${baseURL}/BusinessUnit/GetLockBUPaymentModes?BUCode=${buCode}`;

  try {
    const response = await api.get(uri);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {}

  return {
    model: null,
    didError: true,
    message: "No se pudieron obtener las modalidades de pago bloqueadas",
    errorMessage: "No se pudieron obtener las modalidades de pago bloqueadas",
  };
};
