import { FC, useEffect, useMemo, useState } from "react";
import { AccountSearch } from "../components/Account/AccountSearch";
import { useAppSelector } from "../store/hooks";
import { VEN_CODE, CI_CODE } from "../components/Account/CreateClientModal";
import { VEN_ID } from "../components/Account/UpdateClientModal";
import { AccountInterface } from "../interfaces/AccountInterface";
import AccountFormPage, {
  emptyPhonePrefix,
  phonePrefixOptions,
} from "../components/Account/AccountFormPage";
import HorizontalPadding from "../components/HorizontalPadding";
import { PrimaryButton } from "../components/Buttons";

const Clients: FC = () => {
  const [client, setClient] = useState<AccountInterface>();
  const [openCreateClientForm, setOpenCreateClientForm] = useState(false);
  const [openUpdateClientForm, setOpenUpdateClientForm] =
    useState<Boolean>(false);
  const user = useAppSelector((state) => state.user.user);

  useEffect(() => {
    if (!!client) {
      setOpenCreateClientForm(false);
      setOpenUpdateClientForm(false);
    }
  }, [client]);

  const countries = useAppSelector((state) => state.inmutable.countries);
  const taxIdentificationTypes = useAppSelector(
    (state) => state.inmutable.taxIdentificationTypes
  );

  const countryId = useMemo(
    () =>
      !!client?.listAccountPhone
        ? client.listAccountPhone[0]?.countryID
        : VEN_ID,
    [client]
  );

  const countryId2 = useMemo(
    () =>
      !!client?.listAccountPhone[1]
        ? client.listAccountPhone[1]?.countryID
        : VEN_ID,
    [client]
  );

  const isEnterprise = useMemo(() => {
    return (
      !!client?.agreementID || (client?.listAuthorizingAccount?.length ?? 0) > 0
    );
  }, [client]);

  const editable = useMemo(() => {
    if (!!isEnterprise && user?.roleName === "Superadministrador") {
      return true;
    }
    if (!!isEnterprise) {
      return false;
    } else {
      return true;
    }
  }, [isEnterprise, user?.roleName]);

  const getPhonePrefix = (phoneNumber: string, countryId: number) => {
    // Delete all chars that are not numbers
    phoneNumber = phoneNumber.replace(/\D/g, "");

    if (countryId === VEN_ID) {
      const prefix = phoneNumber.slice(0, 3);
      const option = phonePrefixOptions.find((p) => p.value === prefix);
      if (!!option) return option;
    }

    return emptyPhonePrefix;
  };

  const getPhoneNumber = (phoneNumber: string, countryId: number) => {
    // Delete all chars that are not numbers
    phoneNumber = phoneNumber.replace(/\D/g, "");

    const prefix = getPhonePrefix(phoneNumber, countryId).value;
    const result = phoneNumber.slice(prefix.length, phoneNumber.length);

    return result;
  };

  const clientInfo = useMemo(() => {
    return {
      fullName: client?.accountFullName ?? "",
      email: !!client?.listAccountEmail?.[0]
        ? client.listAccountEmail[0]?.email
        : "",
      address: client?.fiscalAddress ?? "",
      // First phone
      country:
        countries.find((c) => c.id === countryId) ??
        countries.find((c) => c.id === VEN_ID)!,
      phonePrefix: !!client?.listAccountPhone?.[0]
        ? getPhonePrefix(client.listAccountPhone[0].phoneNumber, countryId)
        : emptyPhonePrefix,
      phone: !!client?.listAccountPhone?.[0]
        ? getPhoneNumber(client.listAccountPhone[0].phoneNumber, countryId)
        : "",

      // Second phone
      country2: countries.find((c) => c.id === countryId2),
      phonePrefix2: !!client?.listAccountPhone?.[1]
        ? getPhonePrefix(client.listAccountPhone[1].phoneNumber, countryId2)
        : phonePrefixOptions.find((o) => o.value === "412")!,
      phone2: !!client?.listAccountPhone?.[1]
        ? getPhoneNumber(client.listAccountPhone[1].phoneNumber, countryId2)
        : undefined,

      idType: taxIdentificationTypes.find(
        (idType) => client?.abreviationName === idType.abreviationName
      )!,
      idNumber: client?.identificationNumber ?? "-1",
    };
  }, [client]);

  return (
    <main className="lg:pl-72">
      {/* Header */}
      <div className="py-8 sm:px-6 lg:px-8 bg-white relative flex items-center justify-between h-32">
        <header className="ml-4 text-2xl font-bold text-gray-700 ">
          Clientes
        </header>
      </div>

      {/* Body */}
      <HorizontalPadding paddingTop>
        <div className="flex flex-1 flex-col bg-white gap-2 rounded-lg border px-8 pb-6 pt-4">
          <h2 className="text-2xl font-semibold text-gray-900 truncate">
            Buscar Cliente
          </h2>

          <div className="flex w-full items-center justify-center mb-6 gap-4">
            <div className="max-w-[40rem] w-full">
              <AccountSearch
                title=""
                placeholder="Cédula/RIF o Nombre..."
                onSelectClient={setClient}
                openCreationModal={setOpenCreateClientForm}
              />
            </div>
          </div>

          {/* Show Client Info */}
          {!!client && (
            <div className="px-4px-4 sm:px-6">
              <div className="flex flex-wrap">
                <div className="w-1/3">
                  <p className="font-light">Nombre completo:</p>
                </div>
                <div className="w-2/3">
                  <p className="font-light px-2">
                    {clientInfo.fullName ? (
                      clientInfo.fullName
                    ) : (
                      <em>No registrado</em>
                    )}
                  </p>
                </div>
              </div>
              <div className="flex flex-wrap">
                <div className="w-1/3">
                  <p className="font-light">Correo electrónico:</p>
                </div>
                <div className="w-2/3">
                  <p className="font-light px-2">
                    {clientInfo.email ? (
                      clientInfo.email
                    ) : (
                      <em>No registrado</em>
                    )}
                  </p>
                </div>
              </div>
              <div className="flex flex-wrap">
                <div className="w-1/3">
                  <p className="font-light">Dirección fiscal:</p>
                </div>
                <div className="w-2/3">
                  <p className="font-light px-2">
                    {clientInfo.address ? (
                      clientInfo.address
                    ) : (
                      <em>No registrada</em>
                    )}
                  </p>
                </div>
              </div>
              <div className="flex flex-wrap">
                <div className="w-1/3">
                  <p className="font-light">Cédula / RIF:</p>
                </div>
                <div className="w-2/3">
                  <p className="font-light px-2">
                    {clientInfo.idType.abreviationName ? (
                      clientInfo.idType.abreviationName
                    ) : (
                      <em>No registrada</em>
                    )}
                    {clientInfo.idNumber}
                  </p>
                </div>
              </div>
              <div className="flex flex-wrap">
                <div className="w-1/3">
                  <p className="font-light">Teléfono:</p>
                </div>
                <div className="w-2/3">
                  <p className="font-light px-2">
                    {clientInfo.phonePrefix.name ? (
                      clientInfo.phonePrefix.name
                    ) : (
                      <em>No registrado</em>
                    )}
                    {clientInfo.phone}
                  </p>
                </div>
              </div>
              {!!clientInfo.phone2 && (
                <div className="flex flex-wrap">
                  <div className="w-1/3">
                    <p className="font-light">Teléfono:</p>
                  </div>
                  <div className="w-2/3">
                    <p className="font-light px-2">
                      {clientInfo.phonePrefix2.name} {clientInfo.phone2}
                    </p>
                  </div>
                </div>
              )}

              {!openCreateClientForm && !openUpdateClientForm && (
                <div className="flex flex-shrink-0 justify-end px-4 py-4">
                  <PrimaryButton
                    onClick={() => setOpenUpdateClientForm(true)}
                    className="px-6"
                    disabled={!editable}
                  >
                    Editar
                  </PrimaryButton>
                </div>
              )}
            </div>
          )}

          {/* Create Client */}
          {!!openCreateClientForm && (
            <AccountFormPage
              initialValues={
                // formik initial values
                {
                  fullName: "",
                  email: "",
                  address: "",
                  country: countries.find(
                    (c) => c.countryCodeIso === VEN_CODE
                  )!,
                  phonePrefix: phonePrefixOptions.find(
                    (o) => o.value === "412"
                  )!,
                  phone: "",
                  country2: countries.find(
                    (c) => c.countryCodeIso === VEN_CODE
                  )!,
                  phonePrefix2: phonePrefixOptions.find(
                    (o) => o.value === "412"
                  )!,
                  phone2: "",
                  idType: taxIdentificationTypes.find(
                    (t) => t.taxIdentificationTypeCode === CI_CODE
                  )!,
                  idNumber: "",
                }
              }
              setSelectedItem={setClient}
              setOpenForm={setOpenCreateClientForm}
            />
          )}

          {/* Update Client */}
          {!!client && openUpdateClientForm && (
            <AccountFormPage
              isUpdate
              ignoreTouched
              initialValues={clientInfo}
              accountItem={client}
              setSelectedItem={setClient}
              disableAllInputs={!editable}
              setOpenForm={setOpenUpdateClientForm}
            />
          )}
        </div>
      </HorizontalPadding>
    </main>
  );
};

export default Clients;
