import { parsePhoneNumber } from "libphonenumber-js";
import {
  Undefinable,
  PaymentMode,
  PDocumentType,
  ShipmentStatus,
  DocumentStatus,
  PaymentStatusEnum,
  AccountPhoneInterface,
  PDocumentStatus,
  DeliveryType,
  ShipmentService,
} from "../interfaces";
import { DailyOperationStatus } from "../interfaces/Dtos/DailyStoreOperationDTO";

// Format name for display
export const formatName = (name: string): string => {
  name = name.toLowerCase();
  let words = name.split(" ");
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }
  let formattedText = words.join(" ");

  return formattedText;
};

// Format phone number
export const formatPhoneNumber = (
  phoneObject: Undefinable<AccountPhoneInterface>
): string => {
  if (!phoneObject) return "";
  return phoneObject.countryPhoneAccessCode
    ? phoneObject.countryPhoneAccessCode + "-" + phoneObject.phoneNumber
    : phoneObject.phoneNumber;
  // const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");
  // const formattedPhoneNumber = cleanedPhoneNumber.replace(
  //   /^(\d{2})(\d{3})(\d{7})$/,
  //   "($1) $2-$3"
  // );

  // return formattedPhoneNumber;
};

// Merchant Format 1000.00 (number) -> 100000 (string)
export const merchantFormat = (number: number): string => {
  return number.toFixed(2).toString().replace(/[.,]/g, "");
};
// Bancaribe Format 1000 (number) -> 1000.00 (string)
export const bancaribeFormat = (number: number): string => {
  return number.toFixed(2);
};

export const shipmentStatusFormat = (status: ShipmentStatus) => {
  switch (status) {
    case ShipmentStatus.DRAFT:
      return "En Creación";
    case ShipmentStatus.ACTIVE:
      return "Activa";
    case ShipmentStatus.ANNULLED:
      return "Anulada";
    case ShipmentStatus.REPLACED:
      return "Sustituida";
    case ShipmentStatus.DELIVERED:
      return "Entregada";
    default:
      return "Desconocida";
  }
};

export const deliveryTypeFormat = (deliveryType: DeliveryType) => {
  switch (deliveryType) {
    case DeliveryType.AT_HOME:
      return "A Domicilio";
    case DeliveryType.AT_OFFICE:
      return "En Tienda";
    case DeliveryType.INTERNATIONAL:
      return "Internacional";
    default:
      return "Desconocido";
  }
};

export const shipmentServiceFormat = (service: ShipmentService) => {
  switch (service) {
    case ShipmentService.STANDARD:
      return "Estándar";
    case ShipmentService.DOCUMENT:
      return "Sobre";
    case ShipmentService.INTERNATIONAL:
      return "Internacional";
    case ShipmentService.NATIONAL_LOCKER:
      return "Casillero Nacional";
    default:
      return "Desconocido";
  }
};

export const documentStatusFormat = (status: DocumentStatus) => {
  switch (status) {
    case DocumentStatus.ANULLED:
      return "Anulada";
    case DocumentStatus.PAID:
      return "Pagada";
    case DocumentStatus.PENDING:
      return "Pendiente";
    case DocumentStatus.PARTIAL_PAID:
      return "Parcialmente Pagada";
    case DocumentStatus.TO_ISSUE:
      return "Por emitir";
    case DocumentStatus.ISSUED:
      return "Emitida";
    default:
      return "Desconocida";
  }
};

export const pdocumentStatusFormat = (status: PDocumentStatus) => {
  switch (status) {
    case PDocumentStatus.PENDING:
      return "Por emitir factura";
    case PDocumentStatus.TO_APPROVE:
      return "Por aprobar";
    case PDocumentStatus.APPROVED:
      return "Aprobado";
    default:
      return "Desconocido";
  }
};

// BC Phone format
export const convertPhoneNumber = (phoneNumber: string): string => {
  // Remove all non-numeric characters from the phone number
  const numericPhoneNumber = phoneNumber.replace(/\D/g, "");

  // If the phone number starts with a plus sign, remove it
  const strippedPhoneNumber = numericPhoneNumber.startsWith("+")
    ? numericPhoneNumber.slice(1)
    : numericPhoneNumber;

  // If the phone number starts with "58", remove it and add a "0" at the beginning
  const formattedPhoneNumber = strippedPhoneNumber.startsWith("58")
    ? `0${strippedPhoneNumber.slice(2)}`
    : strippedPhoneNumber;

  return formattedPhoneNumber;
};

// Clean Client identification number
export const cleanID = (ID: string): string => {
  return ID.replace(/\D/g, "");
};

// Format phone using Google libphonenumber
export const formatGooglePhone = (phone: string): string => {
  if (phone.length > 11) return phone.split("").slice(0, 11).join("");

  if (phone.length > 6) {
    const phoneNumber = parsePhoneNumber(phone, "VE");
    if (phoneNumber) {
      return convertPhoneNumber(phoneNumber.formatNational());
    }
  }

  return convertPhoneNumber(phone);
};

//
export const extractFirstLetter = (abbreviationName: string): string => {
  // Remove the "-" and return the first character
  return abbreviationName.replace("-", "").charAt(0);
};

/**
 * Format a float string value
 *
 * @param value The input value to be formatted
 * @param setValue A function that takes the formatted value and assigns it to
 *  a variable
 * @param min The minimum allowed value (default is -Infinity)
 * @param max The maximum allowed value (default is Infinity)
 * @returns A boolean indicating whether the formatting was successful or not
 */
export const formatFloat = (
  value: string,
  setValue: (f: string) => void,
  min?: number,
  max?: number
) => {
  let formatted = value;

  if (!/^\d*\.?\d*$/.test(value)) {
    return false;
  }

  if (formatted.length > 1) {
    formatted = formatted.replace(/^0+/, "");
  }
  if (formatted === "") {
    formatted = "0";
  } else if (formatted[0] === ".") {
    formatted = "0" + formatted;
  }

  let result = Number(formatted);
  if (min !== undefined) {
    result = Math.max(min, result);
  }
  if (max !== undefined) {
    result = Math.min(max, result);
  }

  if (value[value.length - 1] === ".") {
    setValue(`${result}.`);
  } else if (value.slice(value.length - 2) === ".0") {
    setValue(`${result}.0`);
  } else {
    setValue(`${result}`);
  }

  return true;
};

export const formatTime = (milliseconds: number) => {
  const minutes = Math.floor(milliseconds / (60 * 1000));
  const seconds = Math.floor((milliseconds % (60 * 1000)) / 1000);
  return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
};

export const paymentModeFormat = (paymentMode?: PaymentMode) => {
  switch (paymentMode) {
    case PaymentMode.CONTADO:
      return "Contado";
    case PaymentMode.COD:
      return "COD";
    case PaymentMode.CREDIT:
      return "Crédito Corporativo";
    case PaymentMode.BOXOFFICE_CREDIT:
    case PaymentMode.DEST_BOXOFFICE_CREDIT:
      return "Crédito Taquilla";
    default:
      return "Desconocido";
  }
};

export const formatDate = (date: string) => {
  return new Date(date).toLocaleDateString("es-VE", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const formatPDocumentType = (type: PDocumentType) => {
  switch (type) {
    case PDocumentType.CHD:
      return "Cheque Devuelto";
    case PDocumentType.FCT:
      return "Factura";
    case PDocumentType.FOI:
      return "Factura Otros";
    case PDocumentType.NDC:
      return "Nota de Crédito";
    case PDocumentType.NDD:
      return "Nota de Débito";
    case PDocumentType.ODF:
      return "Orden Factura";
    default:
      return "Desconocido";
  }
};

export const formatPaymentStatus = (status: PaymentStatusEnum) => {
  switch (status) {
    case PaymentStatusEnum.APPROVE:
      return "Aprobado";
    case PaymentStatusEnum.PENDING:
      return "Por conciliar";
    case PaymentStatusEnum.REJECT:
      return "Rechazado";
    case PaymentStatusEnum.CANCELED:
      return "Anulado";
    default:
      return "Desconocido";
  }
};

export const formatDailyOperationStatus = (statues: DailyOperationStatus) => {
  switch (statues) {
    case DailyOperationStatus.PENDING:
      return "Pendiente";
    case DailyOperationStatus.APPROVED:
      return "Aprobado";
    case DailyOperationStatus.REJECTED:
      return "Rechazado";
    case DailyOperationStatus.ANULLED:
      return "Anulado";
    default:
      return "Desconocido";
  }
};

export const formatAmount = (amount: number) => {
  return (+amount.toFixed(2)).toLocaleString("de-DE", {
    minimumFractionDigits: 2,
  });
};

export const mercantilDateFormat = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const mercantilPhoneFormat = (phone: string) => {
  if (phone.startsWith("04")) {
    return "58" + phone.slice(1);
  }
  return phone;
};
