import { FC, useEffect, useMemo, useState } from "react";
import ShipmentForm from "./ShipmentForm";
import {
  alertService,
  getAccount,
  loaderService,
  verifyAccountBlocked,
} from "../../services";
import { PlusIcon } from "@heroicons/react/20/solid";
import { setShipmentCreateDrafts } from "../../store/slices";
import {
  PaymentMode,
  ShipmentInterface,
  ShipmentService,
  ShipmentStatus,
  Undefinable,
} from "../../interfaces";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { PrimaryButton, SecondaryButton } from "../../components/Buttons";
import { validateAccount } from "../../utils";
import { RangeDeclareDto } from "../../interfaces/Dtos";
import { BlockedReason } from "../../components/Account/AccountBlocked";

interface ShipmentFormListProps {
  /**
   * On submit callback
   */
  onSubmit?: () => void;
}
const ShipmentFormList: FC<ShipmentFormListProps> = ({
  onSubmit = () => {},
}) => {
  const dispatch = useAppDispatch();
  const loading = loaderService.useIsLoading();
  const user = useAppSelector((state) => state.user);
  const countries = useAppSelector((state) => state.inmutable.countries);
  const maxDiscount = useAppSelector((state) => state.inmutable.maxDiscount);
  const shipmentDrafts = useAppSelector(
    (state) => state.shipmentCreate.shipmentDrafts
  );
  const rangeMinDeclareList = useAppSelector(
    (state) => state.inmutable.rangeDeclareList
  );

  const [element, setElement] = useState(0);
  const [errors, setErrors] = useState<Map<number, Undefinable<string>>>(
    new Map()
  );

  const isCreationDisabled = useMemo(() => {
    return (
      shipmentDrafts.length > 0 &&
      (!shipmentDrafts[0] ||
        (shipmentDrafts[0].paymentMode !== PaymentMode.CONTADO &&
          shipmentDrafts[0].paymentMode !== PaymentMode.BOXOFFICE_CREDIT))
    );
  }, [shipmentDrafts]);

  const Forms = useMemo(() => {
    const nDrafts = shipmentDrafts.length;

    return Array(nDrafts)
      .fill(null)
      .map((_, index) => {
        return (
          <div key={index} id={`shipment-form-index-${index}`}>
            <ShipmentForm
              index={index}
              error={errors.get(index)}
              active={index === element}
              disablePaymentMode={nDrafts > 1}
              shipperIsEditable={index === 0 && shipmentDrafts.length <= 1}
              onDelete={() => {
                if (index === element) {
                  setElement(-1);
                } else if (index < element) {
                  setElement(element - 1);
                }
              }}
              onArrowClick={() => {
                setElement(index === element ? -1 : index);
                setErrors((errors) => new Map(errors.set(index, undefined)));
              }}
            />
          </div>
        );
      });
  }, [shipmentDrafts.length, errors, element]);

  const verifyShipments = async () => {
    let error = false;
    let scroll = false;

    for (let index = 0; index < shipmentDrafts.length; index++) {
      const shipment = shipmentDrafts[index];
      const errorString = await draftIsValid(shipment);

      if (!!errorString && errorString.trim().length > 0) {
        error = true;
        setElement(-1);
        setErrors((errors) => new Map(errors.set(index, errorString)));

        if (!scroll) {
          const element = document.getElementById(
            `shipment-form-index-${index}`
          );
          if (element) {
            const elementRect = element.getBoundingClientRect();
            window.scrollTo({
              top: elementRect.top,
              behavior: "smooth",
            });
            scroll = true;
          }
        }
      }
    }

    return error;
  };

  const handleSubmit = async () => {
    const error = await verifyShipments();

    if (!error) {
      onSubmit();
    }
  };

  const minDeclaredValue = (weight: number, rangeList: RangeDeclareDto[]) => {
    return (
      rangeList.find((r) => r.minWeight < weight && weight <= r.maxWeight)
        ?.minDeclareValue ?? 0
    );
  };

  const draftIsValid = async (draft: Partial<ShipmentInterface>) => {
    let msg = "";
    let valid = true;

    if (!draft.shipper) {
      msg += (valid ? "Faltan los siguientes campos: " : ", ") + "Remitente";
      valid = false;
    }
    if (!draft.consignee) {
      msg += (valid ? "Faltan los siguientes campos: " : ", ") + "Destinatario";
      valid = false;
    }
    if (!draft.paymentMode) {
      msg +=
        (valid ? "Faltan los siguientes campos: " : ", ") + "Modalidad de Pago";
      valid = false;
    }
    if (!draft.buSource) {
      msg += (valid ? "Faltan los siguientes campos: " : ", ") + "Origen";
      valid = false;
    }
    if (!draft.consigneeAddress) {
      msg += (valid ? "Faltan los siguientes campos: " : ", ") + "Destino";
      valid = false;
    }
    if (!draft.pieces) {
      msg += (valid ? "Faltan los siguientes campos: " : ", ") + "Piezas";
      valid = false;
    }
    if (!draft.pieces?.length) {
      msg += (valid ? "Faltan los siguientes campos: " : ", ") + "Piezas";
      valid = false;
    }
    if (!draft.items) {
      msg += (valid ? "Faltan los siguientes campos: " : ", ") + "Servicios";
      valid = false;
    }

    if (!!draft.discount && +draft.discount > maxDiscount) {
      if (valid) msg = `El descuento no puede ser mayor al ${maxDiscount}%`;
      else msg += `.\nEl descuento no puede ser mayor al ${maxDiscount}%`;

      valid = false;
    }

    if (draft.shipper) {
      let shipperValid = true;
      let validations = await validateAccount(draft.shipper, countries);
      if (validations.error) {
        msg +=
          (valid ? "" : "\n ") + "Remitente inválido: " + validations.message;
        valid = false;
        shipperValid = false;
      }

      // Verify that the account is not blocked
      const isBlocked = await verifyAccountBlocked(
        draft.shipper.id,
        draft.service!,
        draft.paymentMode!,
        draft.buSource!.code
      );

      if (isBlocked.didError || isBlocked.model === null) {
        alertService.warn(
          "Hubo un error al verificar si el remitente está bloqueado."
        );
      } else if (isBlocked.model.length > 0) {
        const blockedReason = isBlocked.model[0].blockedReasonID;

        if (blockedReason === BlockedReason.OTHER) {
          msg +=
            (valid ? "" : "\n ") +
            (shipperValid ? "Remitente bloqueado: E" : ", e") +
            "l cliente está bloqueado para recibir o realizar envíos";
          valid = false;
          shipperValid = false;
        } else {
          msg +=
            (valid ? "" : "\n ") +
            (shipperValid ? "Remitente bloqueado: E" : ", e") +
            "l cliente no puede realizar envíos de crédito hasta que no cancele su deuda pendiente";
          valid = false;
          shipperValid = false;
        }
      }
    }
    if (draft.consignee) {
      let consigneeValid = true;
      let validations = await validateAccount(draft.consignee, countries);
      if (validations.error) {
        msg +=
          (valid ? "" : "\n ") +
          "Destinatario inválido: " +
          validations.message;
        valid = false;
        consigneeValid = false;
      }

      // Verify that the account is not blocked
      const isBlocked = await verifyAccountBlocked(
        draft.consignee.id,
        draft.service!,
        draft.paymentMode!,
        draft.buSource!.code
      );

      if (isBlocked.didError || isBlocked.model === null) {
        alertService.warn(
          "Hubo un error al verificar si el destinatario está bloqueado."
        );
      } else if (isBlocked.model.length > 0) {
        const blockedReason = isBlocked.model[0].blockedReasonID;

        if (blockedReason === BlockedReason.OTHER) {
          msg +=
            (valid ? "" : "\n ") +
            (consigneeValid ? "Destinatario bloqueado: E" : ", e") +
            "l cliente está bloqueado para recibir o realizar envíos";
          valid = false;
          consigneeValid = false;
        } else {
          msg +=
            (valid ? "" : "\n ") +
            (consigneeValid ? "Destinatario bloqueado: E" : ", e") +
            "l cliente no puede realizar envíos de crédito hasta que no cancele su deuda pendiente";
          valid = false;
          consigneeValid = false;
        }
      }
    }

    if (
      !draft.boxAccount &&
      draft.service === ShipmentService.STANDARD &&
      draft.paymentMode !== PaymentMode.CREDIT
    ) {
      const weight =
        draft.pieces?.reduce((acc, piece) => acc + piece.weight, 0) ?? 0;
      const minVal = minDeclaredValue(weight, rangeMinDeclareList);

      if (
        parseFloat(draft.declaredValue ? draft.declaredValue : "0") < minVal
      ) {
        const error = `El valor declarado no puede ser menor a ${minVal.toFixed(
          2
        )}$`;
        msg = valid ? error : msg + "\n" + error;
        valid = false;
      }
    }

    return valid === false ? msg : undefined;
  };

  useEffect(() => {
    const updateAccount = async (id: string) => {
      return await getAccount(id);
    };

    const updateAccounts = async () => {
      const drafts = await Promise.all(
        shipmentDrafts.map(async (draft) => {
          const shipper =
            (await updateAccount(draft.shipper?.id ?? "")) ?? undefined;
          const consignee =
            (await updateAccount(draft.consignee?.id ?? "")) ?? undefined;
          return {
            ...draft,
            shipper,
            consignee,
          };
        })
      );

      dispatch(setShipmentCreateDrafts(drafts));
    };

    updateAccounts();

    // Shippers and consignees only need to be updated once when rendering this
    // component.Do not add shipmentDrafts to the dependency list to avoid an
    // infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    setErrors(new Map());
  }, [shipmentDrafts]);

  return (
    <div className="flex flex-1 flex-col">
      {/* Shipments */}
      <div className="flex flex-1 flex-col gap-4">
        {shipmentDrafts.length === 0 && (
          <div
            className="flex flex-1 items-center justify-center"
            style={{ minHeight: "10rem" }}
          >
            <p className="text-gray-500 leading-5">No hay guías registradas</p>
          </div>
        )}

        {Forms}
      </div>

      {/* Footer */}
      <div className="flex flex-1 justify-end gap-4 items-center mt-8">
        <div>
          <SecondaryButton
            className="flex gap-1 w-36"
            disabled={isCreationDisabled}
            onClick={async () => {
              const error = await verifyShipments();
              if (error) return;

              setElement(shipmentDrafts.length);
              dispatch(
                setShipmentCreateDrafts([
                  ...shipmentDrafts,
                  {
                    shipper: shipmentDrafts[0].shipper,
                    pieces: [],
                    items: [],
                    paymentMode: shipmentDrafts[0].paymentMode,
                    buSource: user.businessUnit,
                    status: ShipmentStatus.DRAFT,
                  },
                ])
              );
            }}
          >
            <PlusIcon className="h-5 w-5 flex-none" aria-hidden="true" />
            Añadir Guía
          </SecondaryButton>
        </div>

        <div>
          <PrimaryButton
            disabled={loading}
            onClick={handleSubmit}
            className="flex w-36"
          >
            CONTINUAR
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default ShipmentFormList;
